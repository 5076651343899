<div class="raw-content-page-layout">
  <!-- Privacy Policy -->
  <app-title title="Privacy Policy"></app-title>
  <app-policy-card [policyBody]="body">
    <!-- Body -->
    <ng-template #body>
      <div *ngIf="product" class="policy-content">
        <p>
            This Privacy Policy explains how <span class="font-color">{{ CONFIG.title }}</span> (“we,” “our,” or “us”) collects, uses, 
            and shares information when you use the <span class="font-color">{{ product.title }}</span> 
            {{ product.type }} (the “Service”). By using our Service, you agree to the collection and use of 
            information in accordance with this policy.
        </p>
        
        <div class="block">
          <p><strong>Information We Collect</strong></p>
          <p>
              Currently, our Service does not collect personal data. However, we do utilize 
              third-party services that may collect data used to identify you:
          </p>
          <ul>
              <li>Firebase Analytics</li>
              <li>Firebase Storage</li>
              <li>Firebase Realtime Database</li>
              <li>OpenAI and ChatGPT AI</li>
          </ul>
        </div>
    
        <div class="block">
          <p><strong>Data Retention and Deletion</strong></p>
          <p>
              We retain user credentials solely for login purposes until a user requests deletion. 
              Users can request deletion of their data through the app, and it will be removed 
              within a few days.
          </p>
        </div>
    
        <div class="block">
          <p><strong>Cookies</strong></p>
          <p>
              Our Service does not explicitly use cookies. However, third-party services may 
              use cookies to enhance their functionality.
          </p>
        </div>
    
        <div class="block">
          <p><strong>Service Providers</strong></p>
          <p>
              We may employ third-party companies and individuals to facilitate our Service or 
              analyze how it is used. These third parties may have access to your information 
              only to perform tasks on our behalf and are obligated not to disclose or use 
              it for any other purpose.
          </p>
        </div>
    
        <div class="block">
          <p><strong>Security</strong></p>
          <p>
              We strive to use commercially acceptable means to protect your information. 
              However, no method of transmission over the internet or electronic storage is 
              100% secure, and we cannot guarantee absolute security.
          </p>
        </div>
    
        <div class="block">
          <p><strong>Links to Other Sites</strong></p>
          <p>
              Our Service may contain links to third-party sites. We recommend reviewing the 
              Privacy Policy of any external site you visit. We are not responsible for the 
              content, privacy policies, or practices of any third-party sites or services.
          </p>
        </div>
    
        <div class="block">
          <p><strong>Children’s Privacy</strong></p>
          <p>
              Our Service does not address anyone under the age of 13. We do not knowingly 
              collect personal information from children under 13. If we discover that a 
              child under 13 has provided us with personal information, we will delete it 
              immediately.
          </p>
        </div>
    
        <div class="block">
          <p><strong>Changes to This Privacy Policy</strong></p>
          <p>
              We may update our Privacy Policy from time to time. We will notify you of any 
              changes by posting the new Privacy Policy on this page. You are advised to 
              review this policy periodically for any changes.
          </p>
        </div>
    
        <div class="block">
          <p><strong>Contact Us</strong></p>
          <p>
              If you have any questions or suggestions about our Privacy Policy, please 
              contact us at:
          </p>
          <ul>
              <li>Email: <a href="mailto:{{ CONFIG.email}}">{{ CONFIG.email }}</a>
              </li>
              <li>Website: <a href="{{ CONFIG.website }}" target="_blank" rel="noopener noreferrer">mobtechi.com</a>
              </li>
          </ul>
        </div>
    
        <div class="block">
          <p><strong>Follow Us on Social Media:</strong></p>
          <ul>
              <li *ngFor="let social of CONFIG.socialMedia | keyvalue">
                  <a href="{{social.value.url}}" 
                     target="_blank" rel="noopener noreferrer">{{social.value.name}}</a>
              </li>
          </ul>
        </div>
      </div>
    </ng-template>
  </app-policy-card>
</div>