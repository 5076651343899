export const CONFIG = {
  version: "1.1.7",
  title: 'MobTechi',
  since: '30-O5-2022',
  description: `Your Trusted Digital Partner. We're experts in making great websites, cool mobile apps, and strong tech stuff for new businesses. We simplify complex things, make affordable solutions, and turn your ideas into reality. We're not just tech folks; we're here to help and give smart advice all along. Mixing smart ideas with practical work, we boost your online presence from start to finish. Choose MobTechi for a smart and skilled team that helps your startup shine online.`,
  website: "https://mobtechi.web.app",
  email: "mobtechi.team@gmail.com",
  logo: "https://firebasestorage.googleapis.com/v0/b/mobtechi.appspot.com/o/assets%2Flogo.png?alt=media",
  contactUs: {
    mail: {
      name: "Mail",
      cssClass: "at",
      url: "mailto:mobtechi.team@gmail.com",
    },
    googleplay: {
      name: "Play Store",
      cssClass: "google-play",
      url: "https://play.google.com/store/apps/dev?id=7579037844327234040",
    },
    github: {
      name: "GitHub",
      cssClass: "github",
      url: "https://github.com/orgs/MobTechi/repositories",
    }
  },
  socialMedia: {
    whatsapp: {
      name: "WhatsApp",
      cssClass: "whatsapp",
      url: "https://chat.whatsapp.com/KsTPXx5Aejh1wXdfRkoVpw"
    },
    instagram: {
      name: "Instagram",
      cssClass: "instagram",
      url: "https://www.instagram.com/mobtechi",
    },
    youtube: {
      name: "YouTube",
      cssClass: "youtube",
      url: "https://www.youtube.com/@mobtechi",
    }
  }
};
